
import { defineComponent, reactive, toRefs, ref, onMounted } from 'vue'
import Cookies from 'js-cookie'
import { getCodeImg, login } from './../api/index'
import { Md5 } from 'ts-md5/dist/md5'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import axios from 'axios'
export default defineComponent({
  name: 'Login',
  setup() {
    const loginRef = ref()
    const router = useRouter()
    const deviceData = reactive({
      loginFormData: {
        username: '',
        password: '',
        types: 1,
        code: '',
        uuid: '',
      },
      codeUrl: '',
      // 验证码开关
      captchaOnOff: true,
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      },
      loading: false,
    })
    const data = toRefs(deviceData)
    const getCode = () => {
      getCodeImg().then((res: any) => {
        deviceData.captchaOnOff =
          res.captchaOnOff === undefined ? true : res.captchaOnOff
        if (deviceData.captchaOnOff) {
          deviceData.codeUrl = 'data:image/gif;base64,' + res.img
          deviceData.loginFormData.uuid = res.uuid
        }
      })
    }
    const handleLogin = async () => {
      loginRef.value.validate(async (valid: any) => {
        if (valid) {
          deviceData.loading = true
          const { username, password, code, uuid } = deviceData.loginFormData
          let md5 = Md5.hashStr(password)
          let params = new URLSearchParams()
          params.append('username', username)
          params.append('password', md5)
          // params.append('code',code);
          // params.append('uuid',uuid);

          //let params ={
          //  username,password,code,uuid
          // }
          console.log(params)
          await login(params).then((res: any) => {
            deviceData.loading = false
            let { code, data } = res
            if (code == 0) {
              sessionStorage.setItem('token', data.token)
              sessionStorage.setItem('editAuth', data.editAuth)
              sessionStorage.setItem('userInfo', JSON.stringify(data))
              axios.defaults.headers.Authorization = data.token
              router.push('/')
            } else {
              ElMessage.error(res.errMsg)
            }
          })
        } else {
          deviceData.loading = false
          return false
        }
      })
    }
    onMounted(() => {
      //getCode();
    })
    return {
      ...data,
      loginRef,
      getCode,
      handleLogin,
    }
  },
})
